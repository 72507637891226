body{background: #fff;}

.clear{clear: both;}

.header{
  background: black;
  color: white;
  padding: 15px;
  margin: 0 0 20px;
  h1{font-size: 20px; margin: 0; padding: 0; font-weight: bold;}
  p{margin: 0;}

  .button, .button:hover{
    background: transparent;
    font-size: 40px;
    padding: 10px;
    margin: 0;
  }
  .dropdown-pane{
    top: 92px !important;
    .menu a{
      color: blue;
      text-align: right;
      width: 250px;
      background: #eeeded;
      margin: 0 0 10px;
    }
  }
  a, a:visited{
    color: white;
    text-decoration: none;
  }
}

@media screen and (max-width: 425px) {
  .header p {display: none;}
}

.card, .card:hover, .card:visited{
  background: #eeeded;
  display: block;
  // color: black;
  // text-decoration: none;
  margin: 0 0 20px;
  h2{
    float: left;
    font-size: 18px;
    font-weight: bold;
    margin: 18px 0 0 20px;
  }
  .icon{
    float: left;
    padding:0 0 0 20px;
    background:#cecdcd;
    width: 60px;
    height: 60px;
  }
  i{font-size: 40px;}
}

.container{
  background: #eeeded;
  margin: 0 0 20px;
  padding: 10px;
  h2{
    font-size: 24px;
    font-weight: bold;
  }
  h3{
    font-size: 18px;
    font-weight:bold;
  }
  h4{
    font-size: 16px;
    font-weight: bold;
  }
  p{
    font-size: 14px;
  }

}



            a:focus{text-decoration: underline;}
